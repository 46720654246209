import * as React from "react";
import {Container, Stack} from "react-bootstrap";

const HistoryMain = () => {
    return (
      <div className="h-auto bg-dark text-white">
          <Container>
              <Stack gap={3}>
                  <h1 className="text-center">Our History</h1>
                  <div className="history">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nec tincidunt praesent semper feugiat nibh sed pulvinar proin gravida. Molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit. Pellentesque nec nam aliquam sem. Id cursus metus aliquam eleifend mi in nulla posuere. Viverra nam libero justo laoreet sit. Ornare massa eget egestas purus viverra accumsan in. Lobortis scelerisque fermentum dui faucibus in ornare. Purus viverra accumsan in nisl nisi scelerisque. Molestie nunc non blandit massa enim. Massa ultricies mi quis hendrerit dolor magna eget est. Id leo in vitae turpis massa sed elementum tempus egestas. Massa massa ultricies mi quis hendrerit dolor magna eget. Eget egestas purus viverra accumsan in nisl nisi scelerisque eu. Sem et tortor consequat id porta nibh venenatis. Mattis ullamcorper velit sed ullamcorper morbi tincidunt. Platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras. Turpis egestas maecenas pharetra convallis posuere. Nunc mattis enim ut tellus elementum. Quis eleifend quam adipiscing vitae proin sagittis.
                  </div>
              </Stack>
          </Container>
      </div>
    );
}

export default HistoryMain;